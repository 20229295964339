import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { Link, Typography, VStack } from "@smartrent/ui";

import { FullScreenError } from "@/react/common/FullScreenError";
import { formatCode } from "@/lib/format";

import { Section } from "./Section";
import { RegenerateExpiringLinkButton } from "./RegenerateExpiringLinkButton";
import { AlloyPassDownload } from "./AlloyPassDownload";
import { AlloySaltoDownload } from "./AlloySaltoDownload";
import { AlloyYaleDownload } from "./AlloyYaleDownload";
import { useExpiringLinkContext } from "./ExpiringLinkContext";

interface AccessCodeAndInstructionsProps {
  instructions: string;
  accessCode: string;
}

interface DeviceAccessCode {
  accessCode: string;
  deviceName: string;
}

const AccessCodeAndInstructions = ({
  instructions,
  accessCode,
}: AccessCodeAndInstructionsProps) => {
  return (
    <VStack spacing={16}>
      {instructions ? <Typography>{instructions}</Typography> : null}
      <Typography
        type="title"
        font="bold"
        style={styles.accessCode}
        color="primary"
      >
        {formatCode(accessCode)}
      </Typography>
    </VStack>
  );
};

interface AccessCodeListProps {
  codesDevices: DeviceAccessCode[];
  marketingName: string | null | undefined;
  propertyName: string | null | undefined;
}

const AccessCodeList = ({
  codesDevices,
  marketingName,
  propertyName,
}: AccessCodeListProps) => {
  const { firstAccessCode, additionalAccessCodes } = useMemo(
    () => ({
      firstAccessCode: codesDevices.length > 0 ? codesDevices[0] : null,
      additionalAccessCodes: codesDevices.splice(1),
    }),
    [codesDevices]
  );

  return (
    <VStack spacing={32}>
      {firstAccessCode ? (
        <AccessCodeAndInstructions
          instructions={
            marketingName && propertyName
              ? `You have been given access to unit ${marketingName} at ${propertyName} which uses a smart lock. Please use the code below to open ${firstAccessCode.deviceName}:`
              : `You have been given access to a unit that uses a smart lock. Please use the code below to open ${firstAccessCode.deviceName}:`
          }
          accessCode={firstAccessCode.accessCode}
        />
      ) : null}
      {additionalAccessCodes.map((deviceAccessCode, idx) => (
        <AccessCodeAndInstructions
          key={idx}
          instructions={`Please use the code below to open ${deviceAccessCode.deviceName}:`}
          accessCode={deviceAccessCode.accessCode}
        />
      ))}
    </VStack>
  );
};

const AppDownloadLinks = ({
  showAlloyYale,
  showAlloyPass,
  showAlloySalto,
}: {
  showAlloyYale: boolean | undefined;
  showAlloyPass: boolean | undefined;
  showAlloySalto: boolean | undefined;
}) => {
  if (showAlloySalto) {
    return (
      <AlloySaltoDownload
        message={
          "Please download our companion app, SmartRent Resident App, prior to your move in date to access your home."
        }
      />
    );
  } else if (showAlloyYale) {
    return (
      <AlloyYaleDownload
        message={
          "Please download our SmartRent app, prior to your move in date to access your home."
        }
      />
    );
  } else if (showAlloyPass) {
    return (
      <AlloyPassDownload
        message={
          "Please download our companion app, Alloy Pass, prior to your move in date to access your home."
        }
      />
    );
  } else {
    return null;
  }
};

export const ExpiringLinkHeader = () => {
  const {
    accessCode,
    codesDevices,
    firstName,
    showAlloyPass,
    showAlloySalto,
    showAlloyYale,
    buildingAccessCodes,
    buildingAccessType,
    marketingName,
    propertyName,
    status,
    showAlloyParking,
    isMissingVaultCode,
  } = useExpiringLinkContext();

  let content: React.ReactNode;

  const alloyParkingUrl = useMemo(
    () =>
      `https://alloyparking.com/${propertyName
        ?.toLowerCase()
        .replace(/\s/g, "-")}`,
    [propertyName]
  );

  if (["regenerate", "regenerated"].includes(status)) {
    content = (
      <>
        <Typography>
          The link you used is no longer active. Please click the button below
          to have a new link sent to the mobile number and/or email address
          associated with your account.
        </Typography>
        <RegenerateExpiringLinkButton />
      </>
    );
  } else if (status === "expired") {
    content = (
      <Typography>
        We're sorry, but the Access Code you are trying to access is inactive.
      </Typography>
    );
  } else if (status === "not_found") {
    content = (
      <FullScreenError
        title="Not Found"
        message="We can't seem to find the page you're looking for."
        hideLinks={false}
        hideSidebar={true}
      />
    );
  } else {
    content = (
      <VStack spacing={32}>
        {accessCode && !(codesDevices && codesDevices.length > 0) ? (
          <AccessCodeAndInstructions
            instructions={
              marketingName && propertyName
                ? `You have been given access to unit ${marketingName} at ${propertyName} which uses a smart lock. Please use the code below to open the door:`
                : `You have been given access to a unit that uses a smart lock. Please use the code below to open the door:`
            }
            accessCode={accessCode}
          />
        ) : null}

        {isMissingVaultCode && (
          <Typography color="warning">
            We're currently having trouble adding this pin code to the lock
          </Typography>
        )}

        {codesDevices && codesDevices.length > 0 ? (
          <AccessCodeList
            codesDevices={codesDevices}
            marketingName={marketingName}
            propertyName={propertyName}
          />
        ) : null}

        {buildingAccessCodes !== null &&
        (buildingAccessType === "pin" || buildingAccessType === "code")
          ? buildingAccessCodes?.map((code, id) => (
              <View key={id}>
                <AccessCodeAndInstructions
                  instructions="You have been given access to a property. Please use this code below followed by # to access the property and any parking garages."
                  accessCode={code}
                />
              </View>
            ))
          : null}
        {showAlloyParking ? (
          <Typography style={styles.parkingLinkMargin}>
            {
              "Use your community access code to enter the parking area. Register to use guest parking at the community here: "
            }
            {
              <Link target="_blank" href={alloyParkingUrl}>
                {alloyParkingUrl}
              </Link>
            }
          </Typography>
        ) : null}

        {buildingAccessCodes !== null && buildingAccessType === "ble" ? (
          <AccessCodeAndInstructions
            instructions="You have been given access to a property. Please follow the instructions below to access the property."
            accessCode={""}
          />
        ) : null}

        {buildingAccessCodes !== null && buildingAccessType === "fob" ? (
          <Typography>
            You have been given access to a property. Please use your FOB to
            access the property.
          </Typography>
        ) : null}

        <AppDownloadLinks
          showAlloyYale={showAlloyYale}
          showAlloyPass={showAlloyPass}
          showAlloySalto={showAlloySalto}
        />
      </VStack>
    );
  }

  return (
    <Section>
      {firstName ? (
        <Typography type="title" style={styles.greeting} color="primary">
          Hello, {firstName}!
        </Typography>
      ) : null}

      {content}
    </Section>
  );
};

const styles = StyleSheet.create({
  greeting: {
    marginBottom: 20,
  },
  accessCode: {
    fontSize: 48,
    letterSpacing: 10,
  },
  parkingLinkMargin: { marginTop: 24 },
});
